import React from 'react'
import { graphql } from 'gatsby'
import Sidebar from '../components/Sidebar'
import Layout from '../components/Layout'
import Icons from '../elements/icons'

class Skills extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">Skills</h1>
                <div className="page__body">
                  <p />

                  <p>
                    {/* <p> HTML</p>
                    <p> CSS</p> */}
                    <Icons name="html-5" />
                    <Icons name="css-3" />
                    <Icons name="sass" />
                  </p>

                  <p>
                    {/* <p> JavaScript</p> */}
                    <Icons name="javascript" />
                    <Icons name="react" />
                    <Icons name="zeit" />
                    <Icons name="gatsby" />
                    <Icons name="graphql" />
                    <Icons name="nodejs" />
                  </p>

                  <p>
                    <Icons name="git-icon" />
                    <Icons name="netlify" />
                    <Icons name="digital-ocean" />
                    <Icons name="nginx" />
                  </p>

                  {/* <p>full</p>

                  <p>HTML: HTML5 JSX Jekyll</p>

                  <p>CSS: CSS3 SASS Bootstrap Foundation Materialize</p>

                  <p>
                    JavaScript: Vanilla JS ES2015 ES6 React GatsbyJS Node.js
                  </p>

                  <p>Backend: Firebase GraphQL MongoDB</p>

                  <p>CMS: Contentful Prismic WordPress</p>

                  <p>Build Tools: Webpack Yarn Babel gulp.js npm Grunt</p>

                  <p>Others: MDX Netlify</p>

                  <p>Version control: Git GitHub GitLab Bitbucket</p>

                  <p>Server: Nginx XAMPP DigitalOcean</p>

                  <p>Tracking: JIRA Podio Trello Asana Zapier Hubstaff</p>

                  <p>Other: Photoshop InVision Adobe XD</p>

                  <p>e-commerce: Shopify Stripe</p>

                  <p>SEO: SEO/SEM --></p>

                  <p>## Soft skills</p>

                  <p>Integrity</p>

                  <p>Dependability</p>

                  <p>Effective communication</p>

                  <p>Open-mindedness</p>

                  <p>Teamwork</p>

                  <p>Creativity</p>

                  <p>Problem-solving</p>

                  <p>Adaptability</p>

                  <p>Organization</p>

                  <p>Willingness to learn </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Skills

export const skillsPageQuery = graphql`
  query SkillsQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
          icon
        }
        author {
          name
          email
          linkedin
          twitter
          github
          twitch
          codepen
        }
        authorLinks {
          social
          socialUrl
          socialIcon
        }
      }
    }
  }
`
